import React, { useEffect, useMemo, useState } from 'react';
import './LiveMatch.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { motion } from 'framer-motion';
import Axios from 'axios';
import { Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { openGame } from 'api/gameService';
import axios from 'axios';

// Interface for match participant
interface Participant {
  _id: string;
  name: string;
}

// Interface for match score
interface Score {
  homeScore: number;
  awayScore: number;
}

// Interface for match data
interface MatchEvent {
  _id: string;
  masterLeagueId: string;
  leagueName: string;
  sportName: string;
  startEventDate: string;
  isLive: boolean;
  participants: Participant[];
  score: Score;
}

export const LiveMatch: React.FC = () => {
  const [matches, setMatches] = useState<MatchEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [ipClient, setIpClient] = useState()
  const [isLoading, setIsLoading] = useState(false);
  useMemo(() => {
    Axios.get('https://geolocation-db.com/json/').then((res) => {
      setIpClient(res.data?.ip);
    }).catch(() => {
      // ถ้า API แรกล้มเหลว ลอง API สำรอง
      Axios.get("https://api64.ipify.org?format=json")
        .then((res) => {
          setIpClient(res.data?.ip);
        })
        .catch(() => {
          Axios.get('https://ipapi.co/json/').then((res) => {
            setIpClient(res.data?.ip);
          })
        });
    });
  }, [])
  // Carousel options
  const options = {
    items: 3,
    loop: false,
    margin: 15,
    nav: true,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    navText: ['<span>&lt;</span>', '<span>&gt;</span>'],
    responsive: {
      0: {
        items: 1.2,
        margin: 10,
      },
      576: {
        items: 1.2,
        margin: 10,
      },
      768: {
        items: 2,
        margin: 15,
      },
      992: {
        items: 3,
        margin: 15,
      },
      1200: {
        items: 4,
        margin: 15,
      },
    },
  };

  // Fetch live match data
  const fetchLiveMatches = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://callback.usa-wt02.link/callback/bti/event?live=true&locale=th');
      const data = response.data;

      if (data && data.events) {
        // Filter for live football matches
        const footballMatches = data.events.filter(
          (match: MatchEvent) => match.isLive && match.sportName === "ฟุตบอล"
        );
        setMatches(footballMatches);
      }
    } catch (error) {
      console.error('Error fetching live matches:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLiveMatches();

    // Refresh data every 60 seconds
    const interval = setInterval(() => {
      fetchLiveMatches();
    }, 180000);

    return () => clearInterval(interval);
  }, []);

  // Format date to Thai format
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}, ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  const handleOpenGame = async () => {
    try {
      const responseGame = await openGame(44, false, ipClient)
      if (responseGame.result) {
        // Open the game in a new tab without setTimeout
        const newWindow = window.open(responseGame.result, '_blank', "noreferrer");
        
        // Check if the window was successfully opened
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          console.error('Failed to open game window. Popup might be blocked.');
        } else {
          newWindow.focus();
        }
      } else {
        console.error('No game URL returned from the server');
      }
    } catch (e) {
      console.error('Error opening game:', e);
    }
  }

  return (
    <motion.div

      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 1.3 } }}
      className="live-match-container"
    >
      <div style={{ maxWidth: (typeof window !== 'undefined' && window.innerWidth < 576) ? '95vw' : '85vw' }}>
        <div className="container py-3 py-md-5">
          <div className="game-list">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center mb-4">
                <div className="d-flex align-items-center">
                  <div className="bg-danger rounded-circle me-2 pulse-dot"></div>
                  <h2 className="fs-4 fw-bold text-white mb-0">Live Now</h2>
                  <div
                    className="refresh-button"
                    onClick={() => fetchLiveMatches()}
                  >
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            ) : matches.length > 0 ? (
              <OwlCarousel className="owl-theme" {...options}>
                {matches.map((match) => (
                  <div className="item" key={match._id}>
                    <div className="match-card">
                      <div className="match-header">
                        <div className="league-info">
                          <img
                            src={`https://frontgame.s3.ap-southeast-1.amazonaws.com/league/${match.masterLeagueId}.png`}
                            onError={(e) => {
                              (e.target as HTMLImageElement).onerror = null;
                              (e.target as HTMLImageElement).src = 'https://frontgame.s3.ap-southeast-1.amazonaws.com/ball.png';
                            }}
                            alt="League"
                          />
                          <div className="league-name">{match.leagueName}</div>
                        </div>
                        <div className="match-time">
                          <div className="live-badge">LIVE</div>
                          <div className="time">{formatDate(match.startEventDate)}</div>
                        </div>
                      </div>

                      <div className="teams-container">
                        <div className="team">
                          <div className="team-logo">
                            <img
                              src={`https://frontgame.s3.ap-southeast-1.amazonaws.com/team/${match.participants[0]._id}.png`}
                              onError={(e) => {
                                (e.target as HTMLImageElement).onerror = null;
                                (e.target as HTMLImageElement).src = 'https://googlecdn.live/teams/uploads/logo-none.png';
                              }}
                              alt={match.participants[0].name}
                            />
                          </div>
                          <div className="team-name">{match.participants[0].name}</div>
                        </div>

                        <div className="vs">VS</div>

                        <div className="team">
                          <div className="team-logo">
                            <img
                              src={`https://frontgame.s3.ap-southeast-1.amazonaws.com/team/${match.participants[1]._id}.png`}
                              onError={(e) => {
                                (e.target as HTMLImageElement).onerror = null;
                                (e.target as HTMLImageElement).src = 'https://googlecdn.live/teams/uploads/logo-none.png';
                              }}
                              alt={match.participants[1].name}
                            />
                          </div>
                          <div className="team-name">{match.participants[1].name}</div>
                        </div>
                      </div>

                      <div className="match-footer">
                        <div className="score">
                          score {match.score.homeScore} : {match.score.awayScore}
                        </div>
                        <div className="bet-button" onClick={handleOpenGame}>
                        เดิมพันทันที
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              <div className="no-matches">
                <p>ไม่มีการแข่งขันที่กำลังถ่ายทอดสด</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default LiveMatch;
